<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Match the pipet name and function to its picture.</p>
      <p class="mb-4">
        <v-simple-table>
          <tr>
            <th style="text-align: left; width: 8%">Part</th>
            <th style="text-align: left; width: 46%" class="pl-2">Name</th>
            <th style="text-align: left; width: 46%" class="pl-2">Function</th>
          </tr>
          <tr class="mb-n6">
            <td>a)</td>
            <td>
              <v-select
                v-model="inputs.input1"
                :items="items1"
                item-text="text"
                item-value="value"
                class="mb-n6"
                label="Select Option"
                outlined
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input2"
                :items="items2"
                item-text="text"
                item-value="value"
                class="mb-n6"
                label="Select Option"
                outlined
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
          <tr class="mb-8">
            <td />
            <td colspan="2"><v-img style="max-width: 700px" :src="imageName" class="mb-8" /></td>
          </tr>
        </v-simple-table>
      </p>

      <p>
        <v-simple-table>
          <tr>
            <th style="text-align: left; width: 8%">Part</th>
            <th style="text-align: left; width: 46%" class="pl-2">Name</th>
            <th style="text-align: left; width: 46%" class="pl-2">Function</th>
          </tr>
          <tr class="mb-n6">
            <td>b)</td>
            <td>
              <v-select
                v-model="inputs.input3"
                :items="items1"
                item-text="text"
                item-value="value"
                class="mb-n6"
                label="Select Option"
                outlined
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input4"
                :items="items2"
                item-text="text"
                item-value="value"
                class="mb-n6"
                label="Select Option"
                outlined
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
          <tr class="mb-8">
            <td />
            <td colspan="2"><v-img style="max-width: 700px" :src="imageName2" class="mb-8" /></td>
          </tr>
        </v-simple-table>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S2Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      items1: [
        {text: 'Volumetric pipet', value: 'volumetric'},
        {text: 'Disposable pipet', value: 'disposable'},
      ],
      items2: [
        {
          text: 'Deliver a fixed measured volume',
          value: 'fixed',
        },
        {text: 'Deliver a variable unmeasured volume', value: 'unmeasured'},
        {
          text: 'Deliver a variable but measured volume',
          value: 'measured',
        },
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/volPipette.png';
    },
    imageName2() {
      return '/img/assignments/disposablePipetteWide.png';
    },
  },
};
</script>
<style scoped></style>
